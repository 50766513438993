<template>
  <b-card no-body class="card-custom">
    <template #header>
      <div class="card-title">
        <h3 class="card-label">Returns</h3>
      </div>
    </template>

    <div class="card card-custom gutter-b card-stretch card-shadowless">
      <!-- User Interface controls -->
      <div>
        <b-card no-body>
          <b-tabs pills card>
            <b-tab title="New return requests" active>
              <DataTable
                ref="dt"
                :value="newReturns"
                dataKey="id"
                :lazy="true"
                :paginator="true"
                :rows="rowCount"
                class="mt-0"
                :totalRecords="totalRecords"
                @page="onPage($event, 1)"
                @sort="onSort($event, 1)"
                :loading="loading"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
              >
                <!-- <b-card-header class="p-4">
                  <b-row>
                    <b-col lg="2" md="3" sm="6">
                      <b-form-group class="m-0">
                        <b-form-select
                          v-model="filters.order_status"
                          placeholder="Order Status"
                          :options="statusList"
                          @change="onFilter(1)"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="2" md="3" sm="6">
                      <b-form-group class="m-0">
                        <b-form-select
                          v-model="filters.payment_status"
                          placeholder="Payment Status"
                          :options="paymentStatus"
                          @change="onFilter(1)"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="2" md="3" sm="6">
                      <b-form-group class="m-0">
                        <span class="p-input-icon-right w-100">
                          <b-form-input
                            v-model="filters.name"
                            placeholder="Search ID, Name.."
                            class="py-2"
                            @keydown.enter="onFilter(1)"
                          />
                          <i class="las la-search"></i>
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-header> -->

                <template #empty>
                  <div class="text-center text-muted py-5">Order not found.</div>
                </template>

                <Column field="sl" header="#" class="SL-n">
                  <template #body="slotProps">
                    {{ slotProps.index + 1 }}
                  </template>
                </Column>
                <Column field="name" header="Product name" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.name }}
                  </template>
                </Column>
                <Column field="customer" header="Customer Name" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.customer_name }}
                  </template>
                </Column>
                <Column field="reason" header="Reason" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.return_reason }}
                  </template>
                </Column>
                <Column field="msg" header="Reason Message" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.return_reason_msg }}
                  </template>
                </Column>
                <Column
                  field="order_search"
                  header="Status"
                  ref="order_search"
                  :sortable="true"
                >
                  <template #body="slotProps">
                    <div>{{ slotProps.data.status }}</div>
                  </template>
                </Column>

                <Column field="action" header="Action" :sortable="true">
                  <template #body="slotProps">
                    <b-button
                      size="sm"
                      pill
                      :to="`/return-status-form/${slotProps.data.id}`"
                      class="py-2 px-4 border bg-white bg-hover-primary"
                      >Change Status</b-button
                    >
                    <b-button
                      size="sm"
                      pill
                      :to="`/order-detail/${slotProps.data.id}`"
                      class="py-2 px-4 border bg-white bg-hover-primary"
                      >Detail</b-button
                    >
                  </template>
                </Column>
              </DataTable>
            </b-tab>
            <b-tab title="Processing & Refunded">
              <DataTable
                ref="returnsdt"
                :value="returns"
                dataKey="id"
                :lazy="true"
                :paginator="true"
                :rows="returnsRowCount"
                class="mt-0"
                :totalRecords="returnsTotalRecords"
                @page="onPage($event, 2)"
                @sort="onSort($event, 2)"
                :loading="returnLoading"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
              >
                <!-- <b-card-header class="p-4">
                  <b-row>
                    <b-col lg="2" md="3" sm="6">
                      <b-form-group class="m-0">
                        <b-form-select
                          v-model="returnsFilters.order_status"
                          placeholder="Order Status"
                          :options="statusList"
                          @change="onFilter(2)"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="2" md="3" sm="6">
                      <b-form-group class="m-0">
                        <b-form-select
                          v-model="returnsFilters.payment_status"
                          placeholder="Payment Status"
                          :options="paymentStatus"
                          @change="onFilter(2)"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="2" md="3" sm="6">
                      <b-form-group class="m-0">
                        <span class="p-input-icon-right w-100">
                          <b-form-input
                            v-model="returnsFilters.name"
                            placeholder="Search ID, Name.."
                            class="py-2"
                            @keydown.enter="onFilter(2)"
                          />
                          <i class="las la-search"></i>
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-header> -->

                <template #empty>
                  <div class="text-center text-muted py-5">Order not found.</div>
                </template>

                <Column field="sl" header="#" class="SL-n">
                  <template #body="slotProps">
                    {{ slotProps.index + 1 }}
                  </template>
                </Column>
                <Column field="name" header="Product name" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.name }}
                  </template>
                </Column>
                <Column field="customer" header="Customer Name" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.customer_name }}
                  </template>
                </Column>
                <Column field="reason" header="Reason" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.return_reason }}
                  </template>
                </Column>
                <Column field="msg" header="Reason Message" :sortable="true">
                  <template #body="slotProps">
                    {{ slotProps.data.return_reason_msg }}
                  </template>
                </Column>
                <Column
                  field="order_search"
                  header="Status"
                  ref="order_search"
                  :sortable="true"
                >
                  <template #body="slotProps">
                    <div>{{ slotProps.data.status }}</div>
                  </template>
                </Column>

                <Column field="action" header="Action" :sortable="true">
                  <template #body="slotProps">
                    <b-button
                      v-if="slotProps.data.status != 'refunded'"
                      size="sm"
                      pill
                      :to="`/return-status-form/${slotProps.data.id}`"
                      class="py-2 px-4 border bg-white bg-hover-primary"
                      >Change Status</b-button
                    >
                    <b-button
                      size="sm"
                      pill
                      :to="`/order-detail/${slotProps.data.id}`"
                      class="py-2 px-4 border bg-white bg-hover-primary"
                      >Detail</b-button
                    >
                  </template>
                </Column>
              </DataTable>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";

import RetunService from "@/core/services/api/return";

export default {
  components: {
    DataTable,
    Column,
    InputText,
    Dropdown,
  },
  data() {
    return {
      statusList: [{ value: null, text: "All" }],
      paymentStatus: [
        { value: null, text: "All" },
        { text: "Created", value: "created" },
        { text: "Success", value: "success" },
        { text: "Failed", value: "failed" },
      ],
      order_search: null,
      order_type: null,
      newReturns: [],
      returns: [],
      imgPath: process.env.VUE_APP_API_STORAGE_URL,
      loading: false,
      totalRecords: 0,
      rowCount: 1,
      lazyParams: {},

      returnLoading: false,
      returnsTotalRecords: 0,
      returnsRowCount: 1,
      returnsLazyParams: {},
    };
  },
  mounted() {
    this.loading = true;
    this.returnLoading = true;

    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };

    this.returnsLazyParams = {
      first: 0,
      rows: this.$refs.returnsdt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Return",
        route: "/return",
      },
    ]);
    this.getReturnList();
    this.getNewReturnList();
  },
  computed: {
    filters: function () {
      var filters = {
        name: null,
        model: null,
        order_status: null,
      };
      return filters;
    },

    returnsFilters: function () {
      var filters = {
        name: null,
        model: null,
        order_status: null,
      };
      return filters;
    },
  },
  methods: {
    async getReturnList() {
      var response = await RetunService.getReturnList(this.returnsLazyParams);
      if (response != undefined) {
        this.returns = response.data;
        this.returnsTotalRecords = response.total;
        this.returnsRowCount = response.per_page;
      } else {
        this.returns = [];
        this.returnsTotalRecords = 0;
        this.returnsRowCount = 1;
      }

      this.returnLoading = false;
    },
    async getNewReturnList() {
      var response = await RetunService.getNewReturnList(this.lazyParams);
      if (response != undefined) {
        this.newReturns = response.data;
        this.totalRecords = response.total;
        this.rowCount = response.per_page;
      } else {
        this.newReturns = [];
        this.totalRecords = 0;
        this.rowCount = 1;
      }

      this.loading = false;
    },
    onPage(event, tab) {
      this.loading = true;
      if (tab == 1) {
        this.lazyParams = event;
        this.getNewReturnList();
      } else {
        this.returnsLazyParams = event;
        this.getReturnList();
      }
    },
    onSort(event, tab) {
      this.loading = true;
      if (tab == 1) {
        this.lazyParams = event;
        this.getNewReturnList();
      } else {
        this.returnsLazyParams = event;
        this.getReturnList();
      }
    },
    onFilter(tab) {
      this.loading = true;
      if (tab == 1) {
        this.lazyParams.filters = this.filters;
        this.getNewReturnList();
      } else {
        this.returnsLazyParams.filters = this.filters;
        this.getReturnList();
      }
    },
  },
  watch: {
    // order_search(newValue) {
    // 	this.filters.order_search = newValue;
    // 	this.onFilter();
    // }
  },
};
</script>

<style lang="scss">
.p-datatable {
  .p-datatable-wrapper {
    .p-datatable-table {
      tr {
        td:last-child {
          width: 100px;
        }
      }
    }
  }
}
</style>
